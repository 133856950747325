.Fretboard {
    width: 92%;
    display: block;
    margin-block: inherit;
    margin: 40px;
    overflow: auto;
    height: auto;
    background-color: #575757;
    color: white;
}

.print {
    background-color: white !important;
}
.hidden {
    display: none;
}
.title {
    color: #575757;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}

.EmptyString {
    display: inline-block;
    border-radius: 0.5em;
    background-color: black;
    width: 3em;
    height: 2.5em;
    margin: 0.4em;
    text-align: center;
    line-height: 2.5em;
    border: 1px solid black;
}
.Nut {
    display: inline-block;
    background-color: black;
    width: 1em;
    height: 2.5em;
    margin: 0.4em;
    text-align: center;
    line-height: 2.5em;
}

@media screen and (max-width: 1000px) {
    .EmptyString {
        font-size: 12px;
        width: 2.7em;
        height: 1.5em;
        margin: 0.4em;
        line-height: 1.5em;
    }
    .Nut {
        font-size: 12px;
        width: 0.7em;
        height: 1.5em;
        margin: 0.2em;
        line-height: 1.5em;
    }
}
