.FretSigns {
    display: block;
}

.Fret {
    display: inline-block;
    border-radius: 2em;
    background-color: #ffffff;
    color: black;
    width: 3em;
    height: 2em;
    margin: 0.4em;
    text-align: center;
    line-height: 2em;
    border: 1px solid black;
}

.FretNm {
    background-color: cadetblue; /*#4e5166*/
}
.FretSel {
    background-color: black !important;
    color: white;
}

.EmptyStringFret {
    display: inline-block;
    border-radius: 1em;
    background-color: black;
    color: white;
    width: 3em;
    height: 2em;
    margin: 0.4em;
    text-align: center;
    line-height: 2em;
    border: 1px solid black;
}

.NutFret {
    display: inline-block;
    background-color: black;
    color: white;
    width: 1em;
    height: 2.5em;
    margin: 0.4em;
    text-align: center;
    line-height: 2.5em;
}

@media screen and (max-width: 1000px) {
    .Fret {
        font-size: 12px;
        width: 2.7em;
        height: 1.5em;
        margin: 0.4em;
        line-height: 1.5em;
    }
    .EmptyStringFret {
        font-size: 12px;
        width: 2.7em;
        height: 1.5em;
        margin: 0.4em;
        line-height: 1.5em;
    }
    .NutFret {
        font-size: 12px;
        width: 0.7em;
        height: 1.5em;
        margin: 0.2em;
        line-height: 1.5em;
    }
}
