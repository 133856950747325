.App {
    /*text-align: center;*/
}

.marginLeft {
    margin-left: 10px;
}
.marginRight {
    margin-right: 10px;
}
