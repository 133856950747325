.Note {
    display: inline-block;
    border-radius: 0.5em;
    background-color: #333;
    width: 3em;
    height: 2.5em;
    margin: 0.4em;
    text-align: center;
    line-height: 2.5em;
    border: 1px solid black;
}

@media screen and (max-width: 1000px) {
    .Note {
        font-size: 12px;
        width: 2.7em;
        height: 1.5em;
        margin: 0.4em;
        line-height: 1.5em;
    }
}

.NoteNm {
    background-color: cadetblue; /*#4e5166*/
}
.NoteSel {
    background-color: #007bcd !important;
    border: 1px solid #382b66;
}
